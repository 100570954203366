import React from 'react'
// CSS
import 'styles/TermsAndConditions.scss'
// Components
import Layout from 'components/Layout'
import SEO from 'components/SEO'


function TermsAndConditions() {
  return (
    <Layout>
      <SEO title="Algemene voorwaarden" />
      <div className="container" dangerouslySetInnerHTML={{ __html: 'Tekst hier.' }} />
    </Layout>
  )
}

export default TermsAndConditions